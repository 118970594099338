/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        controller = new ScrollMagic.Controller();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery("#mobile-nav").mmenu({
          slidingSubmenus: false,
          offCanvas: {
            position  : "left",
            zposition : "front"
          },
          extensions: ["theme-dark"]
        });

        function menu_condensation(){
          var $mdPlusNav = jQuery('.md-plus-nav');
          var $mdLessNav = jQuery('.md-less-nav');

          if (jQuery(document).scrollTop() > 0) {
            // if you move
            $mdPlusNav.addClass('condensed bg-snow').removeClass('not-condensed bg-ghost no-pad');
            $mdLessNav.addClass('condensed bg-snow').removeClass('not-condensed bg-ghost');
            jQuery('.button-change').addClass('button--flat button-brand').removeClass('button-ghost is-reverse');
            jQuery('.logo').children().css({ fill: '#000' });
            jQuery('.logo---ss').css({ fill: '#0098dc' });


          } else {
            // at top
            $mdPlusNav.removeClass('condensed bg-snow').addClass('not-condensed bg-ghost no-pad');
            $mdLessNav.removeClass('condensed bg-snow').addClass('not-condensed bg-ghost');

            jQuery('.button-change').removeClass('button--flat button-brand').addClass('button-ghost is-reverse');
            jQuery('.logo').children().css({ fill: '#fff' });
            jQuery('.logo---ss').css({ fill: '#FFF' });
          }
        }

        menu_condensation();

        jQuery(window).scroll(function(){
          menu_condensation();
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        jQuery('.carousel').carousel();
        jQuery('#home-carousel').on('slide.bs.carousel', function(e) {
            var from = jQuery('.nav li.active').index();
            var next = jQuery(e.relatedTarget);
            var to =  next.index();

            // This could interest you
            jQuery('.home-carousel-indicators li').removeClass('active').eq(to).addClass('active');

        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
      }
    },
    'get_in_touch': {
      init: function() {

      },
      finalize: function() {
      }
    },
    'job_board': {
      init: function() {

      },
      finalize: function(){

      }
    },
    'events': {
      init: function() {

      },
      finalize: function(){

      }
    },
    'association_management_software': {
      init: function() {

      },
      finalize: function() {

        var scene1 = new ScrollMagic.Scene({
          triggerElement: "#trigger2",
          triggerHook: 0.7,
          offset: 1
        })
        .setTween("#animate2", 1.6, {
          opacity: "1",
          marginTop: "0px"
        })
        .addTo(controller);

        var scene2 = new ScrollMagic.Scene({
          triggerElement: "#trigger3",
          triggerHook: 0.7,
          offset: 1
        })
        .setTween("#animate3", 1.6, {
          opacity: "1",
          marginTop: "0px"
        }) // trigger a TweenMax.to tween
        .addTo(controller);

      }
    },
    'before_after': {
      init: function() {

      },
      finalize: function(){
        jQuery('.before-after-body').imagesLoaded( function() {
          jQuery(".twenty-twenty-target").twentytwenty();
        });
      }
    },
    'member_management_squarespace_plugin': {
      init: function() {

      },
      finalize: function() {
        jQuery('.easy-integration').imagesLoaded( function() {
          jQuery(".twenty-twenty-target").twentytwenty();
        });
      }
    },
    'member_management_wordpress_plugin': {
      init: function() {

      },
      finalize: function() {
        jQuery('.easy-integration').imagesLoaded( function() {
          jQuery(".twenty-twenty-target").twentytwenty();
        });
      }
    },
    'why_use_silkstart': {
      init: function() {},
      finalize: function() {}
    },
    'integration_or_full_website': {
      init: function() {},
      finalize: function() {
        jQuery("#integration_slider").imagesLoaded(function() {
          jQuery("#integration_slider").twentytwenty();
        }); 
        jQuery("#fullbuild_slider").imagesLoaded(function() {
          jQuery("#fullbuild_slider").twentytwenty();
        });
      }
    },
    'before_and_after': {
      init: function() {},
      finalize: function() {
        jQuery("#slider1").imagesLoaded(function() {
          jQuery("#slider1").twentytwenty();
        });
        jQuery("#slider2").imagesLoaded(function() {
          jQuery("#slider2").twentytwenty();
        });
        jQuery("#slider3").imagesLoaded(function() {
          jQuery("#slider3").twentytwenty();
        });
      }
    },
    'feature_sheet': {
      init: function() {},
      finalize: function() {}
    },
    'customers': {
      init: function() {},
      finalize: function() {
        jQuery('a[href*="#"]:not([href="#"])').click(function() {
          var href = jQuery(this).attr('href');
          var customer = jQuery(href);
          jQuery('html, body').animate({
            scrollTop: customer.offset().top
          }, 500);
        });
      }
    },
    'what_is_an_ams': {
      init: function() {},
      finalize: function() {
        jQuery('a[href*="#"]:not([href="#"])').click(function() {
          var href = jQuery(this).attr('href');
          var customer = jQuery(href);
          jQuery('html, body').animate({
            scrollTop: customer.offset().top - 60
          }, 500);
        });
      }
    },
    'get_in_touch': {
      init: function() {},
      finalize: function() {
        $('#ninja_forms_form_14 input[type="submit"]').click(function(e){
          var capterra_vkey = 'b050e474f9be1c0b05e40b614fb893d6',
              capterra_vid = '2085143',
              capterra_prefix = (('https:' == document.location.protocol) ? 'https://ct.capterra.com' : 'http://ct.capterra.com');
          (function(){ 
            var ct = document.createElement('script'); ct.type = 'text/javascript'; ct.async = true; ct.src = capterra_prefix + '/capterra_tracker.js?vid=' + capterra_vid + '&vkey=' + capterra_vkey; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ct, s);
          })();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
